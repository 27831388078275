
#dashboard-container {
    width: 95vw;
    height: 90vh;
    margin: 10% auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

#dashboard-nav {
    width: 100%;
    height: 8%;
    background-color: #30A2FF;
    display: flex
}

#links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#links a {
    line-height: 100%;
    font-size: 10px;
}

#info {
    width: 25%;
    padding:  0 10px;
}

#info p {
    text-align: right;
    word-wrap: break-word;
    font-size: 10px;
}

#info #email {
    font-weight: bold;
}

#dashboard-top {
    width: 100%;
    height: 35%;
    padding: 0 10%;
}

#top-header {
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
}

#dashboard-top-containers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 90%;
    justify-content: space-between;
}

.top-div {
    width: 15%;
    height: 60%;
    max-width: 200px;
    background-color: #30A2FF;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    padding: 14px;
    align-items: center;
    justify-content: center;
}

.top-div p {
    font-size: 10px;
}

#dashboard-bottom {
    height: 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 9%;
}

.bottom-container {
    width: 46%;
    height: 46%;
    margin: 10px 0;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bottom-container ul {
    margin-left: auto;
    margin-right: auto;
}


.bottom-container li {
    font-size: 1.5vw;
}

.bottom-title {
    text-decoration: underline;
    font-size: 1.5vw;
}
