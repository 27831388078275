#help-menu-container {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
    flex-wrap: wrap;
}

 #help-menu-container a {
    font-size: 12px;
    color: grey;
} 

#help-menu-container a.active {
    color: black;
}

@media only screen and (max-width: 600px) {
    #help-menu-container {
       margin: 30px 0;
    }

    #help-menu-container a {
        font-size: 8px;
        padding: 0px 7px;
    }

    
}