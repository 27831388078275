#healthcare-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#healthcare-container {
    width: 100%;
    height: 500px;
    display: flex;
    padding: 20px;
    justify-content: space-evenly;
    padding: 50px;
    margin-bottom: 100px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}


#healthcare-container h3 {
    text-align: left;
    margin-top: 20px;
}

#healthcare-title {
    margin-bottom: 100px;
}

#healthcare-second-title {
    margin: 0;
    padding-bottom: 100px;
    text-align: left;
    margin-bottom: 20px;
}

#healthcare-text {
    width: 45%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 500px;
    padding: 20px;
    margin-top: 30px;
}

#healthcare-text p {
    text-align: left;
}

#healthcare-img {
    width: 45%;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#healthcare-img a {
    color: white;
}

.healthcare-image {
 width: 100%;
}

@media only screen and (max-width: 800px) {

    #healthcare-main {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        margin: 0;
        padding: 0;
    }

    #healthcare-container {
        display: block;
        margin: 0;
        width: 100vw;
    }

    #healthcare-text {
        width: 100%;
    }

    #healthcare-img {
        width: 100%;
        margin-bottom: 80px;
    }

    #healthcare-second-title {
        margin: 50px 0;
    }
}