#team-main {
    width: 100vw;
    height: 100vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#team-image-container {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#team-image-container img {
    height: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

#team-people-container {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-evenly;
}

.team-div {
    width: 30%;
    max-width: 350px;
    height: 100%;
    padding: 20px;
    background-color: blue;
    color: white;
    box-sizing: border-box;
    border-radius: 6px;
}

.team-div h3, p {
    text-align: center;
}

.team-div p {
    font-size: 16px;
    padding: 20px;
    line-height: 1.3;
}

@media only screen and (max-width: 900px) {

    #team-main {
        height: 100%;
    }

    #team-image-container img {
        width: 100%;
    }

    #team-people-container {
        flex-direction: column;
        height: 100%;
        margin: 50px auto;
    }

    .team-div {
        width: 100%;
        margin: 10px auto;
    }

}