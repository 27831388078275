#therapy-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#therapy-container {
    display: flex;
    padding: 50px;
    height: 500px;
    justify-content: space-evenly;
}

#therapy-image-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#therapy-img {
    width: 100%;
}

#therapy-image-container a {
    color: white;
}

#therapy-text-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#therapy-text-container h1, h3 {
    text-align: left;
}

#therapy-text-container h1 {
    margin-bottom: 130px;
}

@media only screen and (max-width: 1000px) {

    #therapy-container {
        display: block;
        width: 100vw;
        height: 100vh;
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
    }

    #therapy-image-container {
        width: 100%;
    }

    #therapy-text-container {
        width: 100%;
    }

    #therapy-text-container h1 {
        margin-bottom: 150px;
    }
}