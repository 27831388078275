#full-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 50px;
    justify-content: space-evenly;
}

#full-image-main {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#full-image-main img {
    width: 100%;
}

#full-text-main {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

#full-text-main h1 {
    text-align: left;
    line-height: 1.5;
    align-items: left;
}

#full-text-main p {
    text-align: left;
    line-height: 1.5;
}

.demo-link {
    color: white;
    font-size: 12px;
    padding: 3px;
}

@media only screen and (max-width: 1000px)  {
    #full-main {
        display: block;
        height: 100%;
        width: 100vw;
        padding: 30px;
    }

    #full-image-main {
        width: 100%;
    }


    #full-text-main {
        width: 100%;
        text-align: left;
    }
}