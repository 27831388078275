#schools-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#schools-container {
    height: 500px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    padding: 50px;
}

#schools-image-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#schools-img {
    width: 100%;
}

#schools-image-container a {
    color: white;
}

#schools-text-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (max-width: 1030px) {
    #schools-container {
        width: 100vw;
        height: 100vh;
        display: block;
        padding: 50px;
    }

    #schools-image-container {
        width: 100%;
    }

    #schools-text-container {
        width: 100%;
    }

    #schools-text-container h1 {
        margin-bottom: 100px;
    }
}

