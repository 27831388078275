#navbar {
    background-color: #F7F7F7;
    box-shadow: none;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



#nav-img {
    width: 60px;
    height: 60px;
}


a {
    padding: 0 10px;
    text-decoration: none;
    color: black;
}

a.active {
    color: black;
    text-decoration: underline;
}

.skills-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: left;
}

.skills-dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    top: 150%;
    width: 300px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    left: 0;
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 1;
}

.skills-dropdown a {
    display: block;
    width: 100%;
    padding: 5px 0;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.hidden {
    display: none;
}

.close-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3%;
    right: 3%
}

.close-btn:hover {
    cursor: pointer;
}

.skills-dropdown button {
    height: 0;
    width: 0;
    border: none;
}

@media only screen and (max-width: 950px)  {

    #navbar {
        height: 50px;
    }

    a {
        font-size: 10px;
        padding: 0 5px;
    }

    #nav-img {
        width: 30px;
        height: 30px;
    }

    .skills-dropdown {
        position: absolute;
        background-color: white;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        top: 150%;
        height: 270px;
        width: 150px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        left: 0;
        padding: 10px;

    }
    
    .skills-dropdown a {
        left: 0;
        font-size: 8.5px;
        text-decoration-thickness : 1px
    }
}


