* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
}

h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion {
  max-width: 600px;
  margin: 1rem auto;
}

#title-container {
  text-align: center;
  padding: 10px;
}

#expand-container {
    padding: 10px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: lightblue;
}


/* .accordion-title:hover {
  background-color: lightblue;
} */

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: lightblue;
  padding: 20px;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
