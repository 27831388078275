#contact-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-main h1 {
    padding-top: 50px;
    color: #00308F;
}

#form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

#form-container button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
