.slider-text-container {
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.first-text {
  line-height: 1.5;
  padding-top: 50px;
}

.second-text {
    margin-bottom: 20px;
    line-height: 1.5;
}

.title {
  margin: 0;
  padding: 20px 0;
}


#slideshow-images {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}




@media only screen and (max-width: 600px) {
  #video-section-container {
    width: 100%;
    height: 600px;
  }

  .header {
    padding-top: 20px;
  }

  .first-text {
    padding: 20px 0;
  }

  .slider-text-container {
    padding: 30px;
  }

  .title {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  .header {
    margin: 0;
  }

  .first-text,
  .second-text {
    font-size: 12px;
  }

  .second-text {
    margin-bottom: 0;
  }
}
