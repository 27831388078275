#cards-section-main {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 150px 0;
    /* background-color: #D7F2F2; */
    /* background-color: #B2A3C3; */
    background-color:  #30A2FF;
}

.cards-section-div {
    max-width: 335px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 15px 0;
    background-color: white;
    /* color: #30A2FF; */
    padding: 50px;
    border-radius: 12px;
    /* background-color: #30A2FF; */
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
}

.cards-section-div p {
    line-height: 1.3;
    padding: 20px 0;
    font-size: 14px;
}

.cards-section-div span {
    padding-bottom: 6px;
    color: blue;
    /* color: #30A2FF; */
    /* color: #00308F; */
    font-size: 22px;
    display: block;
}

.card-image {
    width: 50px;
    margin-bottom: 20px;
}


@media screen and (max-width : 1000px) {
    #cards-section-main {
        display: block;
        padding: 50px 0;
    }

    .cards-section-div {
        width: 90%;
        max-width: 350px;
        margin: 50px auto;
    }

    #solution-screenshot-container {
        display: flex;
        flex-direction: column;
    }

    #solution-screenshot-container img {
        width: 100%;
    }

    
}