#nonprofit-main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nonprofit-container {
  width: 100vw;
  height: 500px;
  display: flex;
  justify-content: space-around;
}

#nonprofit-image-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nonprofit-img {
  width: 100%;
}

#nonprofit-image-container a {
  color: white;
}

#nonprofit-text-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1030px) {
  #nonprofit-container {
    display: block;
    width: 100vw;
    height: 100vh;
    padding: 50px;
  }

  #nonprofit-image-container {
    width: 100%;
  }

  #nonprofit-text-container {
    width: 100%;
  }

  #nonprofit-text-container h1 {
    margin-bottom: 100px;
  }
}
