#footer-container {
    height: 100px;
    display: flex;
    background-color: #F7F7F7;
    margin: 0;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    height: 100px;
}

/* #footer-links-container {
    display: flex;
    flex-direction: column;
} */

#footer-logo-container img {
    height: 50px;
}

#footer-social-container img {
    height: 30px;
}

#footer-container a {
    font-size: 16px;
}



