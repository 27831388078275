#positive {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  padding: 0 50px;
}

#positive-container {
  width: 100%;
}

#positive-slider-container {
  width: 100%;
  height: 500px;
  margin: 50px 0;
}

#positive-text-container {
  display: flex;
  justify-content: space-around;
}

#positive-benefits-div {
  width: 30%;
  text-align: center;
  padding: 50px;
  max-width: 500px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin: 20px 0;
  background-color: lightskyblue;
  border-radius: 10px;
}

#positive-benefits-div:nth-of-type(2) {
  background-color: lavender;
}

#positive-benefits-div:nth-of-type(3) {
  background-color: lightgreen;
}

#positive h3,
ul {
  text-align: left;
}

#positive h3 {
  padding: 0;
  margin: 0;
}

#positive-header {
  margin: 0 0 50px 0;
  font-size: 30px;
}

.positive-list-header {
  margin: 70px 0 30px 0;
  text-align: center;
}

/* #positive ul {
display: table;
margin: 0 auto;
margin-left: 20px;
} */

 #positive li {
  margin: 10px 0;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
} 

ul {
  padding: 20px 0;
}


@media only screen and (max-width: 800px) {
  #positive-benefits-div {
    width: 100%;
    max-width: 450px;
    height: 50%;
    padding: 45px;
    text-align: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 10px;
    margin: 50px 0;
    background-color: lightskyblue;
  }

  ul {
    padding: 0;
  }


  #positive-header {
    margin: 0 0 50px 0;
    font-size: 25px;
  }

  #positive-text-container {
    display: block;
  }

  #positive-benefits-div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }

  #positive-benefits-div:nth-of-type(2) {
    background-color: lavender;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  #positive-benefits-div:nth-of-type(3) {
    background-color: lightseagreen;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  #positive-tips-div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  #positive-img {
    width: 200px;
  }

  #positive-container {
    display: block;
    width: 100%;
    height: 300px;
  }

  #positive-slider-container {
    width: 100%;
    height: 260px;
  }
}

/* #cards-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
}

#card1 {
  background-color: lavender;
}

#card2 {
  background-color: lightseagreen;
}

#card3  {
  background-color: lightblue;
}

ul {
  padding: 10px 0;
}

li {
  line-height: 1.5;
} */